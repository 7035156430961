<template>
  <div class="lottery-detail">
    <van-nav-bar left-arrow @click-left="$router.back()"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <template #right>
        <div class="title-content" @click="goRoom()">
          <div class="lottery-left">
            <img class="lottery-img" :src="lotteryData.picture" alt="" />
            <div class="lottery-info">
              <span class="lottery-info-title">{{ lotteryData.title }}</span>
              <div class="lottery-info-expect">
                <span v-if="lotteryIng.length === 0">{{ $t('lottery.comingSoon') }}</span>
                <span v-else> {{ $t('lottery.lottery') }} {{ lotteryIng.expect }} </span>
              </div>
            </div>
          </div>
          <div class="lottery-right">
            <div class="lottery-time">
              <van-count-down v-if="lotteryEnd > 0" :time="lotteryEnd * 1000" format="mm : ss" />
            </div>
            <div class="lottey-status">
              <span v-if="lotteryIng.length === 0">{{ $t('lottery.time') }}</span>
              <span v-else>
                {{ lotteryEnd > 0 ? $t('lottery.time') : $t('home.waitingForLottery') }}</span>
            </div>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="lottery-list">
      <van-list v-model="Listloading" :finished="Listfinished" :finished-text="$t('common.noMore')" @load="ListonLoad"
        :loading-text="$t('common.loading')">
        <van-cell v-for="item, index in lotteryList" :key="index">
          <template #default>
            <div class="lottery-content">
              <div class="lottery-period">
                <div class="expect">{{ item.expect }}</div>
                <div class="code">
                  <template v-for="(code, index1) in openDwd(item.code)">
                    <span v-if="index1 !== openDwd(item.code).length - 1 || item.status !== '已开奖1'" :key="index1"
                      :class="[item.status === '已开奖1' ? 'hl-item-code-item' : 'item-code-text',]">
                      {{ code }}
                    </span>
                    <img class="code-img" v-else :key="'else' + index1" style="width: 0.625rem; height: 0.625rem"
                      :src="require(`@/assets/images/lottery_history/${code}.png`)" />
                  </template>
                </div>
              </div>
              <div class="lottery-result">
                <div class="time">
                  {{ (item.opentime * 1000) | formatTimeHandler }}
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
      <div class="footer" v-if="isFull">{{ $t('common.noFurtherInformation') }}</div>
    </div>
    <div class="purchase-color" @click="goRoom()">{{ $t('lottery.betNow') }}</div>
  </div>
</template>

<script>
import { getLotteryInfo, getLotteryList } from '@/api/lottery.js'
export default {
  data() {
    return {
      lotteryData: {},
      list: [],
      Listloading: false,
      Listfinished: false,
      pages: 1,
      lotteryList: [],
      total: 1,
      lotteryIng: [],
      lotteryEnd: 0
    }
  },
  computed: {
    isFull() {
      return this.lotteryList.length === this.total
    }
  },
  watch: {
    lotteryEnd: function (val) {
      if (val < 0 && this.lotteryIng.expect !== '') {
        const also = 10 - Math.abs(val % 10)
        if (also === 2) {
          this.getLotteryInfoHandler()
          this.getLotteryListHandler()
        }
      }
    }
  },
  created() {
    this.lotteryData = this.$store.state.lottery || {}
    this.getLotteryInfoHandler()
    this.getLotteryListHandler()
    this.lotteryEndWatch()
    this.lotteryListWatch()
  },
  mounted() { },

  methods: {
    lotteryEndWatch() {
      var that = this
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          that.lotteryEnd--
        }, 0)
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },
    lotteryListWatch() {
      var that = this
      const timer2 = window.setInterval(() => {
        setTimeout(() => {
          that.getLotteryListHandler()
        }, 0)
      }, 10000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer2)
      })
    },
    async getLotteryInfoHandler() {
      const {
        data: { data, ret }
      } = await getLotteryInfo({
        type: this.lotteryData.type
      })
      if (ret === 1) {
        if (data.ing.status === '待开奖') {
          this.lotteryIng = data.ing
          this.lotteryEnd =
            data.ing.endtime - Math.round(new Date().getTime() / 1000)
        }
      }
    },
    async getLotteryListHandler() {
      this.Listloading = true
      const {
        data: { data, ret }
      } = await getLotteryList({
        type: this.lotteryData.type,
        page: this.pages
      })
      this.Listloading = false
      this.isLoading = false
      if (ret === 1) {
        this.total = data.total
        const dataLists = data.lists
        if (data.currentPage > 1) {
          for (let i = 0; i < dataLists.length; i++) {
            this.lotteryList.push(dataLists[i])
          }
        } else {
          this.lotteryList = dataLists
        }
      }
      if (this.lotteryData.length === this.total) {
        this.Listfinished = true
      }
    },
    openDwd(code) {
      if (code === '') {
        return ['']
      } else {
        return code
      }
    },
    resultColor(code) {
      const itemOpen = this.openDwd(code)
      const number = itemOpen[itemOpen.length - 1]
      const color = ['#13cc00', '#ff0000', '#b400ff', '#fff']
      let colorStr = [{ number, color: '', img: '' }]
      if (number === '') {
        colorStr = [{ number: null, color: '' }]
      } else if (parseInt(number) === 5) {
        colorStr = [
          { number, color: color[0] },
          { number, color: color[2] }
        ]
      } else if (parseInt(number) === 0) {
        colorStr = [
          { number, color: color[1] },
          { number, color: color[2] }
        ]
      } else if (number % 2 === 0) {
        colorStr = [{ number, color: color[1] }]
      } else if (number % 2 !== 0) {
        colorStr = [{ number, color: color[0] }]
      }
      return colorStr
    },

    ListonLoad() {
      this.pages++
      this.getLotteryListHandler()
    },

    goRoom() {
      let path = '/user/order2';
      let name = 'userOrder2';
      if (this.lotteryData.type.includes('hl')) {
        path = '/user/order';
        name = 'userOrder';
      }
      this.$router.push({
        path,
        name,
        query: { data: this.lotteryData }
      })
    },
    substrEvent(val) {
      if (val) {
        return val.substr(val.length - 1, 1)
      } else {
        return '/'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lottery-detail {
  height: 100%;
  background-color: #fff;

  .van-nav-bar {
    position: fixed;
    width: 100%;
    height: 130px;

    // background-color: #ff0000;
    /deep/ .van-icon {
      // color: #fff;
    }

    /deep/ .van-nav-bar__content {
      height: 100%;

      /deep/ .van-icon-arrow-left {
        // color: #fff;
        font-size: 40px;
      }

      .van-nav-bar__right {
        display: flex;
        align-items: center;
        padding: 0;

        .title-content {
          display: flex;
          justify-content: space-between;
          width: 550px;
          height: 105px;
          background-color: #fff;
          border-top-left-radius: 52px;
          border-bottom-left-radius: 52px;

          .lottery-left {
            display: flex;
            //   justify-content: flex-start;
            align-items: center;

            .lottery-img {
              padding: 0 10px;
              width: 90px;
              height: 90px;
            }

            .lottery-info {
              text-align: left;
              font-family: 'PingFang-Regular';

              .lottery-info-title {
                font-weight: normal;
                font-size: 30px;
                color: #ff0000;
              }

              .lottery-info-expect {
                color: #646464;
                font-size: 24px;
              }
            }
          }

          .lottery-right {
            padding-right: 25px;
            flex-direction: column;
            justify-content: center;
            display: flex;
            font-family: 'PingFang-Regular';

            .lottery-time {
              color: #ff0000;
              font-size: 30px;
            }

            .lottey-status {
              color: #646464;
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .van-col {
    text-align: center;
  }

  .lottery-list-title {
    padding: 70px 20px 0;
    height: 60px;
    line-height: 60px;
    // border-bottom: 1px solid #e8e8e8;
    font-family: 'PingFang-Regular';
    font-size: 30px;
    color: #000;
    background-color: #fff;
  }

  .lottery-list {
    height: calc(100% - 230px);
    overflow: auto;

    .lottery-content {
      display: flex;
      justify-content: space-between;

      .lottery-period {
        width: 41%;

        .expect {
          font-family: 'PingFang-Regular';
          font-size: 24px;
          color: #646464;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .code {
          span {
            display: inline-block;
          }

          .code-img {
            vertical-align: bottom;
          }

          .hl-item-code-item {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin-top: 20px;
            text-align: center;
            margin-right: 8px;
            color: #ffffff;
            font-size: 24px;
            border-radius: 23px;
            background-color: #4800ff;
          }

          .item-code-text {
            color: #dc1323;
            font-size: 28px;
            margin-right: 16px;
          }

          .lottery-info-code-text {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            background-color: #dc1323;
            border-radius: 50px;
          }
        }
      }

      .lottery-number {
        text-align: center;
        width: 30%;
        display: flex;
        align-items: flex-end;

        .item-code-last {
          width: 100%;
          text-align: center;
          font-family: 'PingFang-Medium';
          font-size: 32px;
        }
      }

      .lottery-result {
        // width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .time {
          font-family: 'PingFang-Regular';
          line-height: 26px;
          font-size: 24px;
          color: #646464;
        }

        .ball-box {
          display: flex;

          .ball {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 5px;
          }
        }
      }
    }

    .footer {
      margin: 30px 0;
      text-align: center;
      height: 40px;
      line-height: 40px;
      font-size: 30px;
      font-family: 'PingFang-Regular';
    }
  }

  .purchase-color {
    position: fixed;
    bottom: 0;
    height: 100px;
    width: 100%;
    line-height: 100px;
    text-align: center;
    color: #fff;
    background-color: #ff0000;
    font-size: 30px;
    font-family: 'PingFang-Medium';
  }
}
</style>
